var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "Layout",
    [
      _c(
        "region-guard",
        {
          attrs: {
            id: _vm.$route.params.id,
            entity: "CAR",
            "has-region": !!_vm.car,
          },
        },
        [
          _c("fleet-header", {
            attrs: { id: _vm.$route.params.id, car: _vm.car },
          }),
          !this.$apollo.queries.car.loading
            ? _c(
                "div",
                { staticClass: "animated fadeIn" },
                [
                  _vm.hasError
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-alert",
                            {
                              class: _vm.errorsInfo.type,
                              attrs: { show: "", variant: _vm.errorsInfo.type },
                            },
                            [_vm._v(" " + _vm._s(_vm.errorsInfo.message) + " ")]
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "b-card-group",
                    { staticClass: "mb-4" },
                    [
                      _vm.hasTracking
                        ? _c("card-widget", {
                            attrs: {
                              title: this.$t(
                                "maintenance.texts.currentOdometer"
                              ),
                              value: _vm.setKmFormat(_vm.originalOdometer),
                              icon: "fa fa-road",
                            },
                          })
                        : _vm._e(),
                      _vm.hasTracking && _vm.isRental
                        ? _c("card-widget", {
                            class: _vm.getPreventiveMaintenanceStatus().class,
                            attrs: {
                              title: this.$t("maintenance.texts.nextReview"),
                              subtitle:
                                _vm.getPreventiveMaintenanceStatus().message,
                              value: _vm.setKmFormat(
                                _vm.car.next_maintenance_odometer
                              ),
                              icon: _vm.getPreventiveMaintenanceStatus().icon,
                              "subtitle-hover": _vm.popoverConfig,
                            },
                          })
                        : _vm._e(),
                      _vm.car && _vm.car.total_fines && _vm.isRental
                        ? _c("card-widget", {
                            attrs: {
                              to: "/fleet/" + _vm.$route.params.id + "/fines",
                              title: this.$t(
                                "fleetProfile.tabs.trafficTickets"
                              ),
                              value: _vm.car.total_fines.toString(),
                              icon: "fa fa-warning",
                            },
                          })
                        : _vm._e(),
                      _vm.car && _vm.car.booking && _vm.isRental
                        ? _c("card-widget", {
                            attrs: {
                              to:
                                "/drivers/profile/" + _vm.car.booking.driver.id,
                              title: this.$t("fleetProfile.buttons.driver"),
                              value: _vm.car.booking.driver.name,
                              reverse: "true",
                              icon: "fa fa-user",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.hasTracking
                    ? _c(
                        "b-card-group",
                        { staticClass: "mb-4" },
                        [
                          _c("b-card", { staticClass: "text-center" }, [
                            _c(
                              "small",
                              { staticClass: "text-provider" },
                              [
                                _c("marquee", [
                                  _vm._v(
                                    "Monitorado por " +
                                      _vm._s(_vm.car.tracking.provider)
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm.car.tracking.engine === "false"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "h1 mb-0 mt-5 driver-behaviour-title",
                                  },
                                  [_vm._v(" Desligado ")]
                                )
                              : _vm._e(),
                            _vm.car.tracking.engine === "true"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "h1 mb-0 mt-5 driver-behaviour-title",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "h1 mb-0 mt-5 driver-behaviour-title",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.car.tracking.speed) +
                                            " Km/h "
                                        ),
                                      ]
                                    ),
                                  ]
                                )
                              : _vm._e(),
                            _vm.tracking
                              ? _c("small", { staticClass: "text-updated" }, [
                                  _vm._v(
                                    " Atualizado " +
                                      _vm._s(
                                        _vm._f("moment")(
                                          _vm.tracking.lastEventAt,
                                          "from",
                                          true
                                        )
                                      ) +
                                      " atrás "
                                  ),
                                ])
                              : _vm._e(),
                            _vm.checkDeviceBeta
                              ? _c(
                                  "div",
                                  [
                                    _c(
                                      "b-badge",
                                      { attrs: { variant: "danger" } },
                                      [_vm._v(" Versão 2.0 KTS ")]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]),
                          _c("b-card", [
                            _c("h3", [
                              _vm._v(
                                _vm._s(_vm.$i18n.t("fleet.titles.carInfo"))
                              ),
                            ]),
                            _c("p", [
                              _c("i", {
                                staticClass: "fa fa-battery-three-quarters",
                              }),
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$i18n.t("fleet.texts.batteryVoltage")
                                  ) +
                                  ": "
                              ),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.trackerData.battery.vehicleBattery ||
                                      _vm.$i18n.t("fleet.texts.noInfoFound")
                                  ) + " V"
                                ),
                              ]),
                            ]),
                            _vm.needsPreventiveMaintenance
                              ? _c(
                                  "p",
                                  { staticClass: "alert alert-warning" },
                                  [
                                    _c("strong", [_vm._v("Atenção:")]),
                                    _vm._v(" Realizar Manutenção Preventiva!"),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c(
                            "b-card",
                            [
                              _c("h3", [
                                _vm._v(
                                  _vm._s(_vm.$i18n.t("fleet.titles.blocks"))
                                ),
                              ]),
                              _c("blocks-info", {
                                attrs: {
                                  "tracker-data": _vm.trackerData,
                                  mode: "info",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.car && _vm.isRental
                    ? _c(
                        "b-row",
                        [
                          _c(
                            "b-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "h3",
                                {},
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "fleet.texts.schedules.maintenancePreventiveSection"
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c(
                                    "b-btn",
                                    {
                                      attrs: { variant: "link" },
                                      on: { click: _vm.onCreateSchedule },
                                    },
                                    [
                                      _c("i", { staticClass: "fa fa-add" }),
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "fleet.texts.schedules.maintenancePreventiveNewScheduleLink"
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c("schedule-table", {
                                ref: "scheduleTable",
                                attrs: {
                                  driver: _vm.car.booking
                                    ? _vm.car.booking.driver
                                    : null,
                                  booking: _vm.car.booking,
                                  car: _vm.car,
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.car && _vm.car.license_number
                    ? _c(
                        "div",
                        [
                          _c(
                            "b-card-group",
                            { staticClass: "mb-4" },
                            [
                              _c(
                                "b-card",
                                { attrs: { title: "Dados do Carro" } },
                                [
                                  _c("p", { staticClass: "card-text" }, [
                                    _c("strong", [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.car.brand.name) +
                                          " " +
                                          _vm._s(_vm.car.model.name) +
                                          " - " +
                                          _vm._s(_vm.getColor(_vm.car.color)) +
                                          " (" +
                                          _vm._s(_vm.car.assemble_year) +
                                          "/" +
                                          _vm._s(_vm.car.model_year) +
                                          ") "
                                      ),
                                    ]),
                                    _c("br"),
                                    _c("span", { staticClass: "text-muted" }, [
                                      _vm._v("Marca/Modelo/Cor"),
                                    ]),
                                  ]),
                                  _c("p", { staticClass: "card-text" }, [
                                    _c("strong", [
                                      _vm._v(_vm._s(_vm.car.category)),
                                    ]),
                                    _c("br"),
                                    _c("span", { staticClass: "text-muted" }, [
                                      _vm._v("Categoria"),
                                    ]),
                                  ]),
                                  _c("p", { staticClass: "card-text" }, [
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.setFuelFormat(_vm.car.fuel, " / ")
                                        )
                                      ),
                                    ]),
                                    _c("br"),
                                    _c("span", { staticClass: "text-muted" }, [
                                      _vm._v("Combustível"),
                                    ]),
                                  ]),
                                  _c("p", { staticClass: "card-text" }, [
                                    _c("strong", [_vm._v(_vm._s(_vm.car.vin))]),
                                    _c("br"),
                                    _c("span", { staticClass: "text-muted" }, [
                                      _vm._v("Chassi"),
                                    ]),
                                  ]),
                                  _vm.car.mobilized_at
                                    ? _c("p", { staticClass: "card-text" }, [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("moment")(
                                                _vm.car.mobilized_at,
                                                "DD/MM/YYYY"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("br"),
                                        _c(
                                          "span",
                                          { staticClass: "text-muted" },
                                          [_vm._v("Data mobilização")]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.car.demobilized_at && _vm.getCarStatus()
                                    ? _c("p", { staticClass: "card-text" }, [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm._f("moment")(
                                                _vm.car.demobilized_at,
                                                "DD/MM/YYYY"
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("br"),
                                        _c(
                                          "span",
                                          { staticClass: "text-muted" },
                                          [_vm._v("Data desmobilização")]
                                        ),
                                      ])
                                    : _vm._e(),
                                  _vm.isBrazil &&
                                  _vm.car &&
                                  _vm.car.digital_document &&
                                  _vm.isRental
                                    ? _c("p", { staticClass: "card-text" }, [
                                        _c(
                                          "a",
                                          {
                                            staticStyle: { cursor: "pointer" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.$refs.pdfViewerModal.show()
                                              },
                                            },
                                          },
                                          [
                                            _c("strong", [
                                              _vm._v("Visualizar Documento"),
                                            ]),
                                          ]
                                        ),
                                        _c("br"),
                                        _c(
                                          "span",
                                          { staticClass: "text-muted" },
                                          [_vm._v("Documento Digital")]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              ),
                              _vm.car.booking
                                ? _c(
                                    "b-card",
                                    { attrs: { title: "Detalhes do aluguel" } },
                                    [
                                      _c("p", { staticClass: "card-text" }, [
                                        _c(
                                          "strong",
                                          [
                                            _c(
                                              "b-link",
                                              {
                                                attrs: {
                                                  to:
                                                    "/drivers/profile/" +
                                                    _vm.car.booking.driver.id,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.car.booking.driver
                                                        .name
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        ),
                                        _c("br"),
                                        _c(
                                          "span",
                                          { staticClass: "text-muted" },
                                          [_vm._v("Motorista Ativo")]
                                        ),
                                      ]),
                                      _vm.car.booking.finished_at
                                        ? _c(
                                            "p",
                                            { staticClass: "card-text" },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("moment")(
                                                      _vm.car.booking
                                                        .started_at,
                                                      "DD/MM/YYYY"
                                                    )
                                                  )
                                                ),
                                              ]),
                                              _c("br"),
                                              _c(
                                                "span",
                                                { staticClass: "text-muted" },
                                                [_vm._v("Período")]
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "p",
                                            { staticClass: "card-text" },
                                            [
                                              _c("strong", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm._f("moment")(
                                                      _vm.car.booking
                                                        .started_at,
                                                      "DD/MM/YYYY"
                                                    )
                                                  ) + " até o momento"
                                                ),
                                              ]),
                                              _c("br"),
                                              _c(
                                                "span",
                                                { staticClass: "text-muted" },
                                                [_vm._v("Período")]
                                              ),
                                            ]
                                          ),
                                      _c("p", { staticClass: "card-text" }, [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(_vm.car.booking.plan.name)
                                          ),
                                        ]),
                                        (
                                          _vm.car.booking.transaction_method
                                            ? _vm.car.booking.transaction_method
                                                .id
                                            : false
                                        )
                                          ? _c(
                                              "span",
                                              { staticClass: "detail" },
                                              [
                                                _vm._v(
                                                  "(" +
                                                    _vm._s(
                                                      _vm.car.booking
                                                        .transaction_type
                                                    ) +
                                                    ")"
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        _c("br"),
                                        _c(
                                          "span",
                                          { staticClass: "text-muted" },
                                          [_vm._v("Plano Ativo")]
                                        ),
                                      ]),
                                      _c("p", { staticClass: "card-text" }, [
                                        _c("strong", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.setPriceFormat(
                                                _vm.car.booking
                                                  .transaction_amount
                                              )
                                            )
                                          ),
                                        ]),
                                        _c("br"),
                                        _c(
                                          "span",
                                          { staticClass: "text-muted" },
                                          [_vm._v("Valor do plano")]
                                        ),
                                      ]),
                                    ]
                                  )
                                : _vm._e(),
                              _c(
                                "b-card",
                                { attrs: { title: "Fornecedor" } },
                                [
                                  [
                                    _c("p", { staticClass: "card-text" }, [
                                      _c(
                                        "strong",
                                        [
                                          _c(
                                            "b-link",
                                            {
                                              attrs: {
                                                to:
                                                  "/admin/contracts/" +
                                                  _vm.car.order.contract.id,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.car.order.contract
                                                    .company_name
                                                )
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("br"),
                                      _c(
                                        "span",
                                        { staticClass: "text-muted" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.setVatFormat(
                                                _vm.car.order.contract
                                                  .company_vat
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("p", { staticClass: "card-text" }, [
                                      _c(
                                        "strong",
                                        [
                                          _c(
                                            "b-link",
                                            {
                                              staticClass: "link-contract",
                                              attrs: {
                                                href: `tel:${_vm.car.order.contract.contact_phone}`,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.car.order.contract
                                                      .contact_phone
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("br"),
                                      _c(
                                        "span",
                                        { staticClass: "text-muted" },
                                        [_vm._v("Telefone")]
                                      ),
                                    ]),
                                    _c("p", { staticClass: "card-text" }, [
                                      _vm._v(" Período total do contrato: "),
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(_vm.car.order.period) +
                                            " meses "
                                        ),
                                      ]),
                                    ]),
                                    _c("p", { staticClass: "card-text" }, [
                                      _vm._v(" Período restante do contrato: "),
                                      _c("strong", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.setContractRemainingTime()
                                            ) +
                                            " "
                                        ),
                                      ]),
                                    ]),
                                    _vm.isRental
                                      ? _c("p", { staticClass: "card-text" }, [
                                          _c(
                                            "strong",
                                            [
                                              _c(
                                                "b-link",
                                                {
                                                  on: {
                                                    click: _vm.showOrderModal,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    " Ver pedido do veículo "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                          _c("br"),
                                        ])
                                      : _vm._e(),
                                  ],
                                  [_c("fleet-tags-group")],
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.isBrazil &&
                  _vm.car &&
                  _vm.car.digital_document &&
                  _vm.isRental
                    ? _c("pdf-viewer-modal", {
                        ref: "pdfViewerModal",
                        attrs: { "document-url": _vm.car.digital_document },
                      })
                    : _vm._e(),
                  _vm.car && _vm.car.order && _vm.isRental
                    ? _c("order-modal", {
                        ref: "orderModal",
                        attrs: {
                          "form-mode": "read",
                          "contract-id": _vm.car.order.contract.id,
                          order: _vm.order,
                        },
                      })
                    : _vm._e(),
                ],
                1
              )
            : _c(
                "div",
                { staticClass: "animated fadeIn" },
                [_c("content-loading")],
                1
              ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }