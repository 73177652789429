<template>
  <Layout>
    <region-guard :id="$route.params.id" entity="CAR" :has-region="!!car">
      <fleet-header :id="$route.params.id" :car="car" />
      <div v-if="!this.$apollo.queries.car.loading" class="animated fadeIn">
        <div v-if="hasError">
          <b-alert show :variant="errorsInfo.type" :class="errorsInfo.type">
            {{ errorsInfo.message }}
          </b-alert>
        </div>
        <b-card-group class="mb-4">
          <card-widget v-if="hasTracking" :title="this.$t('maintenance.texts.currentOdometer')" :value="setKmFormat(originalOdometer)"
                       icon="fa fa-road"
          />
          <card-widget
            v-if="hasTracking && isRental"
            :title="this.$t('maintenance.texts.nextReview')"
            :subtitle="getPreventiveMaintenanceStatus().message"
            :value="setKmFormat(car.next_maintenance_odometer)"
            :icon="getPreventiveMaintenanceStatus().icon"
            :class="getPreventiveMaintenanceStatus().class"
            :subtitle-hover="popoverConfig"
          />
          <card-widget
            v-if="car && car.total_fines && isRental"
            :to="'/fleet/' + $route.params.id + '/fines'"
            :title="this.$t('fleetProfile.tabs.trafficTickets')"
            :value="car.total_fines.toString()"
            icon="fa fa-warning"
          />
          <card-widget
            v-if="car && car.booking && isRental"
            :to="'/drivers/profile/' + car.booking.driver.id"
            :title="this.$t('fleetProfile.buttons.driver')"
            :value="car.booking.driver.name"
            reverse="true"
            icon="fa fa-user"
          />
        </b-card-group>

        <b-card-group v-if="hasTracking" class="mb-4">
          <b-card class="text-center">
            <small class="text-provider">
              <marquee>Monitorado por {{ car.tracking.provider }}</marquee>
            </small>
            <div v-if="car.tracking.engine === 'false'" class="h1 mb-0 mt-5 driver-behaviour-title">
              Desligado
            </div>
            <div v-if="car.tracking.engine === 'true'" class="h1 mb-0 mt-5 driver-behaviour-title">
              <div class="h1 mb-0 mt-5 driver-behaviour-title">
                {{ car.tracking.speed }} Km/h
              </div>
            </div>
            <small v-if="tracking" class="text-updated"> Atualizado {{ tracking.lastEventAt | moment('from', true) }} atrás </small>
            <div v-if="checkDeviceBeta">
              <b-badge variant="danger">
                Versão 2.0 KTS
              </b-badge>
            </div>
          </b-card>
          <b-card>
            <h3>{{ $i18n.t('fleet.titles.carInfo') }}</h3>
            <p>
              <i class="fa fa-battery-three-quarters" />
              {{ $i18n.t('fleet.texts.batteryVoltage') }}: <strong>{{ trackerData.battery.vehicleBattery || $i18n.t('fleet.texts.noInfoFound') }} V</strong>
            </p>
            <p v-if="needsPreventiveMaintenance" class="alert alert-warning"><strong>Atenção:</strong> Realizar Manutenção Preventiva!</p>
          </b-card>
          <b-card>
            <h3>{{ $i18n.t('fleet.titles.blocks') }}</h3>
            <blocks-info :tracker-data="trackerData" mode="info" />
          </b-card>
        </b-card-group>

        <b-row v-if="car && isRental">
          <b-col cols="12">
            <h3 class>
              {{ $t('fleet.texts.schedules.maintenancePreventiveSection') }}
              <b-btn variant="link" @click="onCreateSchedule">
                <i class="fa fa-add" />
                {{ $t('fleet.texts.schedules.maintenancePreventiveNewScheduleLink') }}
              </b-btn>
            </h3>
            <schedule-table ref="scheduleTable" :driver="car.booking ? car.booking.driver : null" :booking="car.booking"
                            :car="car"
            />
          </b-col>
        </b-row>

        <div v-if="car && car.license_number">
          <b-card-group class="mb-4">
            <b-card title="Dados do Carro">
              <p class="card-text">
                <strong> {{ car.brand.name }}  {{ car.model.name }} - {{ getColor(car.color) }} ({{ car.assemble_year }}/{{ car.model_year }}) </strong>
                <br>
                <span class="text-muted">Marca/Modelo/Cor</span>
              </p>
              <p class="card-text">
                <strong>{{ car.category }}</strong>
                <br>
                <span class="text-muted">Categoria</span>
              </p>
              <p class="card-text">
                <strong>{{ setFuelFormat(car.fuel, ' / ') }}</strong>
                <br>
                <span class="text-muted">Combustível</span>
              </p>
              <p class="card-text">
                <strong>{{ car.vin }}</strong>
                <br>
                <span class="text-muted">Chassi</span>
              </p>
              <p v-if="car.mobilized_at" class="card-text">
                <strong>{{ car.mobilized_at | moment('DD/MM/YYYY') }}</strong>
                <br>
                <span class="text-muted">Data mobilização</span>
              </p>
              <p v-if="car.demobilized_at && getCarStatus()" class="card-text">
                <strong>{{ car.demobilized_at | moment('DD/MM/YYYY') }}</strong>
                <br>
                <span class="text-muted">Data desmobilização</span>
              </p>
              <p v-if="isBrazil && car && car.digital_document && isRental" class="card-text">
                <a style="cursor: pointer" @click="$refs.pdfViewerModal.show()">
                  <strong>Visualizar Documento</strong>
                </a>
                <br>
                <span class="text-muted">Documento Digital</span>
              </p>
            </b-card>
            <b-card v-if="car.booking" title="Detalhes do aluguel">
              <p class="card-text">
                <strong>
                  <b-link :to="'/drivers/profile/' + car.booking.driver.id">
                    {{ car.booking.driver.name }}
                  </b-link>
                </strong>
                <br>
                <span class="text-muted">Motorista Ativo</span>
              </p>
              <p v-if="car.booking.finished_at" class="card-text">
                <strong>{{ car.booking.started_at | moment('DD/MM/YYYY') }}</strong>
                <br>
                <span class="text-muted">Período</span>
              </p>
              <p v-else class="card-text">
                <strong>{{ car.booking.started_at | moment('DD/MM/YYYY') }} até o momento</strong>
                <br>
                <span class="text-muted">Período</span>
              </p>
              <p class="card-text">
                <strong>{{ car.booking.plan.name }}</strong>
                <span v-if="car.booking.transaction_method ? car.booking.transaction_method.id : false" class="detail">({{ car.booking.transaction_type }})</span>
                <br>
                <span class="text-muted">Plano Ativo</span>
              </p>
              <p class="card-text">
                <strong>{{ setPriceFormat(car.booking.transaction_amount) }}</strong>
                <br>
                <span class="text-muted">Valor do plano</span>
              </p>
            </b-card>
            <b-card title="Fornecedor">
              <template>
                <p class="card-text">
                  <strong>
                    <b-link :to="'/admin/contracts/' + car.order.contract.id">{{ car.order.contract.company_name }}</b-link>
                  </strong>
                  <br>
                  <span class="text-muted">{{ setVatFormat(car.order.contract.company_vat) }}</span>
                </p>
                <p class="card-text">
                  <strong>
                    <b-link class="link-contract" :href="`tel:${car.order.contract.contact_phone}`">
                      {{ car.order.contract.contact_phone }}
                    </b-link>
                  </strong>
                  <br>
                  <span class="text-muted">Telefone</span>
                </p>
                <p class="card-text">
                  Período total do contrato:
                  <strong>
                      {{ car.order.period }} meses
                  </strong>
                </p>
                <p class="card-text">
                  Período restante do contrato:
                  <strong>
                      {{ setContractRemainingTime() }}
                  </strong>
                </p>
                <p v-if="isRental" class="card-text">
                  <strong>
                    <b-link @click="showOrderModal"> Ver pedido do veículo </b-link>
                  </strong>
                  <br>
                </p>
              </template>
              <template>
                <fleet-tags-group />
              </template>
            </b-card>
          </b-card-group>
        </div>
        <pdf-viewer-modal v-if="isBrazil && car && car.digital_document && isRental" ref="pdfViewerModal" :document-url="car.digital_document" />
        <order-modal v-if="car && car.order && isRental" ref="orderModal" form-mode="read"
                     :contract-id="car.order.contract.id" :order="order"
        />
      </div>
      <div v-else class="animated fadeIn">
        <content-loading />
      </div>
    </region-guard>
  </Layout>
</template>

<script>
import { differenceInCalendarMonths } from 'date-fns';
import Layout from '@layouts/main';
import ContentLoading from '@components/content-loading';
import FleetHeader from '@components/fleet/header';
import CardWidget from '@components/shared/card-widget';
import OrderModal from '@components/modals/orders/orderModal';
import {enumFleetFlags, enumOBDProvider, formatFuel} from '@utils/car';
import {formatNumber, formatPrice, formatVat} from '@utils/numbers';
import ScheduleTable from '@components/schedule/schedule-table';
import {isBrazil} from '@utils/helper-region';
import CAR_PROFILE from '@graphql/car/queries/profile.gql';
import CAR_TRACKING from '@graphql/car/queries/tracking.gql';
import PdfViewerModal from '@components/modals/pdf-viewer';
import RegionGuard from '@components/shared/region-guard/region-keeper';
import transformObjectKeys from 'transform-object-keys';
import {mapGetters} from "vuex";
import BlocksInfo from "@views/fleet/common/blocks-info.vue";
import FleetTagsGroup from '@components/fleet/fleet-tags-group.vue';

export default {
  name: 'FleetProfile',
  page: {
    title: 'Frota',
  },
  components: {
    BlocksInfo,
    PdfViewerModal,
    Layout,
    ContentLoading,
    FleetHeader,
    CardWidget,
    ScheduleTable,
    OrderModal,
    RegionGuard,
    FleetTagsGroup,
  },
  data() {
    return {
      fine_limit: 3,
      car: {},
      mapLoad: false,
      trackingApi: null,
      carProfileStatusCode: 200,
      hasError: false,
      errorsInfo: {
        message: '',
        type: '',
      },
      enumOBDProvider,
      enumFleetFlags,
      preventiveMaintenanceStatusConfig: {
        noData: {
          class: '',
          message: '',
          icon: 'fa fa-wrench'
        },
        normal: {
          class: '',
          message: this.$t('fleetProfile.texts.upToDate'),
          icon: 'fa fa-check text-success'
        },
        caution: {
          class: 'text-warning',
          icon: 'fa fa-warning text-warning',
          message: this.$t('fleetProfile.texts.toSchedule')
        },
        warning: {
          class: 'text-warning-orange',
          icon: 'fa fa-warning text-warning-orange',
          message: this.$t('fleetProfile.texts.toScheduleUrgent')
        },
        urgent: {
          class: 'text-danger',
          icon: 'fa fa-warning text-danger',
          message: this.$t('fleetProfile.texts.late')
        }
      },
    };
  },
  computed: {
    ...mapGetters('user', ['findGroups']),
    ...mapGetters('fleet', ['getRawFleetFlags']),
    checkDeviceBeta() {
      return this.car?.tracking?.version >= 2;
    },
    order() {
      return transformObjectKeys(this.car?.order, { deep: true });
    },
    hasTracking() {
      return !!this.car?.tracking?.provider;
    },
    isBrazil,
    hasVin() {
      return !!this.car?.tracking?.vin;
    },
    isRental() {
      return this.car.product === 'RENTAL';
    },
    originalOdometer() {
      return this.hasTracking ? this.car?.tracking?.odometer : 0;
    },
    trackerData() {
      return { id: this.$route.params.id, ...this.tracking };
    },
    popoverConfig() {
      // Both title and content specified as a function in this example
      // and will be called each time the popover is opened
      return {
        html: true,
        title: () => {
          // Note this is called only when the popover is opened
          return this.$t('fleetProfile.texts.whenScheduleMaintenance');
        },
        content: () => {
          // Note this is called only when the popover is opened
          return `<span class="text-success font-weight-bold text-capitalize">${this.$t('fleetProfile.texts.upToDate')}</span>: ${this.$t(
            'fleetProfile.texts.maintenance.upToDate'
          )}</br>\
                  <span class="text-warning font-weight-bold text-capitalize">${this.$t('fleetProfile.texts.toSchedule')}</span>: ${this.$t(
            'fleetProfile.texts.maintenance.toSchedule'
          )}</br>\
                  <span class="text-warning-orange font-weight-bold text-capitalize">${this.$t('fleetProfile.texts.toScheduleUrgent')}</span>: ${this.$t(
            'fleetProfile.texts.maintenance.toScheduleUrgent'
          )}</br>\
                  <span class="text-danger font-weight-bold text-capitalize">${this.$t('fleetProfile.texts.late')}</span>: ${this.$t(
            'fleetProfile.texts.maintenance.late'
          )}`;
        },
      };
    },
    needsPreventiveMaintenance() {
      try {
        const maintenanceRequiredStatuses = ['INSPECTION', 'MAINTENANCE_FIXES', 'MAINTENANCE_RECURRENT'];
        const forbiddenFlags = ['MAINTENANCE_DRIVER', 'CONTRACT_ENDING'];

        const hasForbiddenFlags = this.getRawFleetFlags.some(flag => forbiddenFlags.includes(flag.name));

        const currentOdometer = this.originalOdometer;
        const nextMaintenanceOdometer = this.car.next_maintenance_odometer - 1000;
        const status = this.car.status;
        return maintenanceRequiredStatuses.includes(status) && currentOdometer >= nextMaintenanceOdometer && !hasForbiddenFlags;
      } catch (error) {
        return false;
      }
    },
  },
  apollo: {
    tracking: {
      query: CAR_TRACKING,
      variables() {
        return {
          input: {
            id: this.$route.params.id,
          },
        };
      },
      update: data => {
        return data.getTracking
      },
    },
    car: {
      query: CAR_PROFILE,
      variables() {
        return {
          id: this.$route.params.id,
        };
      },
      result(data) {
        if (data.data?.car?.tracking?.position) {
          setTimeout(() => {
            this.mapLoad = true;
          }, 700);
        }
        this.getError();
      },
      error(err) {
        const error = { ...err?.graphQLErrors, stack: err.stack };
        this.setCarProfileStatusCode(err?.graphQLErrors[0]?.code);
        if (window.newrelic) {
          window.newrelic.noticeError(new Error(`CAR_PROFILE_ERROR: ${err.message} code: ${err?.graphQLErrors[0]?.code}`), error);
        }
      },
    },
  },
  methods: {
    setContractRemainingTime() {
      if (!this.car?.mobilized_at || !this.car?.order?.period) return 'N/A';

      const mobilizedAt = new Date(this.car.mobilized_at);
      const contractPeriod = this.car.order.period;
      const today = new Date();

      if (isNaN(mobilizedAt.getTime())) return 'N/A';

      const monthsElapsed = differenceInCalendarMonths(today, mobilizedAt);
      const monthsRemaining = contractPeriod - monthsElapsed;

      if (monthsRemaining < 1) return this.$t('contractExpired');
      if (monthsRemaining === 1) return '1 mês';
      if (monthsRemaining > 1) return `${monthsRemaining} meses`;

      return 'N/A';
    },

    showOrderModal() {
      this.$bvModal.show('order-modal');
    },
    getError() {
      if (!this.hasVin && !this.getCarProfileStatusCode500()) {
        this.errorsInfo = { type: 'info', message: this.$t('fleetProfile.labels.waitingDenatranSync') };
        this.hasError = true;
      } else if (this.getCarProfileStatusCode500()) {
        this.errorsInfo = { type: 'danger', message: this.$t('fleetProfile.labels.vehicleDataError') };
        this.hasError = true;
      } else {
        this.errorsInfo = { message: '', type: '' };
        this.hasError = false;
      }
    },
    getCarStatus() {
      return this.car.status === 'RETIRED_RETURN' || this.car.status === 'RETIRED_THEFT' || this.car.status === 'RETIRED_CRASH';
    },
    setFuelFormat(fuel, spacer) {
      return formatFuel(fuel, spacer);
    },
    setCarProfileStatusCode(statusCode = 200) {
      this.carProfileStatusCode = statusCode;
    },
    setKmFormat(value) {
      return formatNumber(value) + 'km';
    },
    setPriceFormat(value) {
      return formatPrice('R$', value);
    },
    setVatFormat(vat) {
      return formatVat(vat);
    },
    getCarProfileStatusCode500() {
      return this.carProfileStatusCode === 500;
    },
    async createScheduleForCar() {
      this.$store
        .dispatch('schedule/createScheduleForCar', {
          variables: {
            carId: this.car.id,
            bookingId: this.car.booking?.id,
            driverId: this.car.booking?.driver?.id,
            odometer: this.car.tracking?.odometer,
          },
        })
        .then(this.$refs.scheduleTable.getListForCar)
        .catch(error => this.showFailAlert('Erro ao criar novo agendamento', error.message));
    },
    onCreateSchedule() {
      const swalConfig = {
        title: 'Você tem certeza?',
        text: 'Você está prestes a criar um agendamento de manutenção para este veículo',
        type: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Criar agendamento',
      };

      this.$swal(swalConfig).then(data => {
        if (data.dismiss) return;

        this.createScheduleForCar();
      });
    },
    showFailAlert(title, message) {
      if (message.includes('before create a new one')) {
        message = 'Já existe um agendamento em andamento para este carro';
      }

      const configSwalFail = {
        type: 'error',
        title: title,
        text: message,
      };

      this.$swal(configSwalFail);
    },
    getColor(carColor) {
      return carColor ? carColor.id : '';
    },
    getOdometerState() {
      if (!this.isBrazil) return 'noData';

      const nextMaintenanceOdometer = this.car.next_maintenance_odometer;
      const odometer = this.originalOdometer

      if (!odometer || !nextMaintenanceOdometer) return 'noData';

      const difference = odometer - nextMaintenanceOdometer;

      if (difference > 0 && difference <= 1000) return 'caution';

      if (difference > 1000 && difference <= 2000) return 'warning';

      if (difference > 2000) return 'urgent';

      return 'normal';
    },
    getPreventiveMaintenanceStatus() {
      return this.preventiveMaintenanceStatusConfig[this.getOdometerState()]
    },
  },
};
</script>

<style lang="scss" scoped>
.info {
  border-color: #8fd9ef;
}

.driver-behaviour-title {
  margin-top: 2rem !important;
}

.text-provider {
  font-size: 15px;
  font-weight: bold;
  color: red;
}

.street-view-image {
  display: block;
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>
